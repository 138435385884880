import { MAGIC_KEY, RN_KEY } from '../constants/storage-keys';

/**
 * Gets the value of an item key in local storage.
 *
 * @param {string} item The item key.
 * @returns {string | null} Returns the value of the item key in local storage.
 */
function getItem(item: string) {
  return window.localStorage.getItem(item);
}

/**
 * Sets the value of an item key in local storage.
 *
 * @param {string} item The item key.
 * @param {string} value The value to set.
 */
function setItem(item: string, value: string) {
  window.localStorage.setItem(item, value);
}

/**
 * Deletes an item key in local storage.
 *
 * @param {string} item The item key.
 */
function deleteItem(item: string) {
  window.localStorage.removeItem(item);
}

export default { getItem, setItem, deleteItem };

/**
 * Reads the custodian id of the user that is currently being impersonated.
 *
 * @returns {string | null} Returns the custodian id of the user that is currently being impersonated or null if the user is not impersonating anyone.
 */
export function readMagicCustodianId() {
  return window.sessionStorage.getItem(MAGIC_KEY);
}

/**
 * Writes the custodian id of the user that is currently being impersonated.
 *
 * @param {number | string} custodianId The custodian id of the user that is currently being impersonated.
 */
export function writeMagicCustodianId(custodianId: number | string) {
  window.sessionStorage.setItem(MAGIC_KEY, custodianId.toString());
}

/**
 * Deletes the custodian id of the user that is currently being impersonated.
 */
export function deleteMagicCustodianId() {
  window.sessionStorage.removeItem(MAGIC_KEY);
}

/**
 * Reads whether the app is running in a React Native environment.
 *
 * @returns {boolean} Returns true if the app is running in a React Native environment, false otherwise.
 */
export function readIsFromReactNative() {
  return getItem(RN_KEY) === 'true';
}

/**
 * Writes whether the app is running in a React Native environment.
 *
 * @param {boolean} isFromReactNative Whether the app is running in a React Native environment.
 */
export function writeIsFromReactNative(isFromReactNative: boolean) {
  setItem(RN_KEY, isFromReactNative.toString());
}

/**
 * Deletes whether the app is running in a React Native environment.
 */
export function deleteIsFromReactNative() {
  deleteItem(RN_KEY);
}

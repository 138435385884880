/**
 * Returns the summary of the impairment based on the provided value.
 *
 * @param {string | null | undefined} impairment The imparement value.
 * @returns {string} Returns the summary of the impairment.
 */
export function impairmentSummary(impairment?: string | null) {
  if (!impairment) {
    return 'None';
  }

  switch (impairment) {
    case 'none':
      return 'None';
    case 'mild':
      return 'Mild';
    case 'medium':
      return 'Moderate';
    case 'severe':
      return 'Severe';
    default:
      return 'None';
  }
}

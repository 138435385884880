function armText(secondPerson = false) {
  return secondPerson ? 'Need an arm for support' : 'Needs an arm for support';
}

function walkerText(secondPerson = false) {
  return secondPerson ? 'Use a walker or cane' : 'Uses a walker or cane';
}

function wheelchairText(secondPerson = false) {
  return secondPerson
    ? 'Use a wheelchair, but can walk short distances'
    : 'Uses a wheelchair, but can walk short distances';
}

function wheelchairWithLiftText(secondPerson = false) {
  return secondPerson
    ? 'Use a wheelchair and need to be lifted in/out of the vehicle'
    : 'Uses a wheelchair and needs to be lifted in/out of the vehicle';
}

/**
 * Returns the summary of the mobility assistance based on the provided value.
 *
 * @param {string | null | undefined} mobilityAssistance The mobility assistance value.
 * @returns {string} Returns the summary of the mobility assistance.
 */
export function mobilitySummary(mobilityAssistance?: string | null) {
  if (!mobilityAssistance) {
    return 'None';
  }

  switch (mobilityAssistance) {
    case 'none':
      return 'None';
    case 'arm':
      return armText();
    case 'walker':
      return walkerText();
    case 'wheelchair':
      return wheelchairText();
    case 'wheelchair_with_lift':
      return wheelchairWithLiftText();
    default:
      return 'None';
  }
}

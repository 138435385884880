/**
 * Determines whether the provided phone number is valid.
 *
 * @param {string | null | undefined} phone The phone number to validate.
 * @returns {boolean} Returns true if the phone number is valid.
 */
export function validPhone(phone?: string | null) {
  return !!(
    // TODO: Ignore the linting error here until once we have Dashboard using
    // this library. Then we can fix the regex to be more accurate.
    // eslint-disable-next-line no-useless-escape, prettier/prettier
    (phone && phone.match(/^\+?1?[-\s\.]?\(?([2-9]+\d+)\)?([-\s\.]?\d+)*$/))
  );
}

/**
 *
 * @param {string | undefined} phone The phone number to normalize.
 * @returns {string | undefined} Returns the normalized phone number.
 */
export function normalizePhone(phone: string | undefined) {
  if (phone) {
    const result = phone.match(/\d/g);

    if (result) {
      return result.join('');
    }

    return undefined;
  }

  return undefined;
}

/**
 * Determines whether the provided email is valid.
 *
 * @param {string | undefined} email The email to validate.
 * @returns {boolean} Returns true if the email is valid.
 */
export function validEmail(email: string | undefined) {
  return !!(email && email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i));
}

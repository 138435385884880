interface Person {
  firstName?: string | null;
  lastName?: string | null;
}

interface Vehicle {
  make?: string | null;
  model?: string | null;
}

/**
 * Returns the full name of the person.
 *
 * @param {Person | null | undefined} person The person object.
 * @returns {string} Returns the full name of the person.
 */
export function personName(person?: Person | null) {
  if (person) {
    return `${person.firstName} ${person.lastName}`;
  }

  return '';
}

function getInitial(text?: string | null) {
  return text && text.length > 0 ? text.charAt(0).toUpperCase() : '';
}

/**
 * Returns the first name and the last initial of the person.
 *
 * @param {Person | null | undefined} person The person object.
 * @returns {string} Returns the first name and the last initial of the person.
 */
export function firstLastInitial(person?: Person | null) {
  if (person) {
    return `${person.firstName} ${getInitial(person.lastName)}.`;
  }

  return '';
}

/**
 * Returns the initials of the person.
 *
 * @param {Person | null | undefined} person The person object.
 * @returns {string} Returns the initials of the person.
 */
export function personInitials(person?: Person | null) {
  if (person) {
    return `${getInitial(person.firstName)}${getInitial(person.lastName)}`;
  }

  return '';
}

/**
 * Returns the description of the vehicle.
 *
 * @param {Vehicle | null | undefined} vehicle The vehicle object.
 * @returns {string} Returns the description of the vehicle.
 */
export function vehicleDescription(vehicle?: Vehicle | null) {
  if (vehicle) {
    return `${vehicle.make ?? ''} ${vehicle.model ?? ''}`.trim();
  }

  return '';
}

/**
 * Converts cents to formatted dollars.
 *
 * @param {number | null | undefined} cents The cents to convert to formatted dollars.
 * @returns {string} Returns the formatted dollars.
 */
export function centsToFormattedDollars(cents?: number | null) {
  if (cents === null || cents === undefined) {
    return '';
  }

  return `$${(cents / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;

  // broken on android
  // return (cents / 100).toLocaleString("en-US", {
  //   style: "currency",
  //   currency: "USD"
  // });
}

/**
 * Converts a money string to dollars.
 *
 * @param {string} moneyString The money string to convert to dollars.
 * @returns {string} Returns the converted dollars.
 */
export function moneyStringToDollars(moneyString: string) {
  return Number(moneyString.replace(/[^0-9.-]+/g, ''));
}
